import React from "react"

import "./index.css"
import "../components/main.css"
import IntroSticky from "@components/introsticky"
import {Icon, Header,Image} from "semantic-ui-react"
import ProjectGroup from "@components/projectblock"
import ProfilePic from "@images/profile-pic.jpeg"
import WorkTimeline from "@components/timeline"
import Layout from "../components/layout";


const IndexPage = ({ data }) => {

  return (<div className="background">
    <Layout/>
    <IntroSticky/>
    <Image src={ProfilePic} textAlign="right" centered circular size="medium" style={{border:"3px solid white"}}/>
    <WorkTimeline/>


    <div style={{textAlign: "center",paddingTop:"20px", paddingBottom:"20px"}}>
      <a rel="noopener noreferrer"  href="https://www.github.com/LucasKiefer" target="_blank"><Icon color="black" name="github" size='huge' link/></a>
      <a rel="noopener noreferrer" href="https://www.linkedin.com/in/lucaskiefer/" target="_blank"><Icon name="linkedin" size='huge'/></a>
      <a rel="noopener noreferrer" href="mailto:keekskiefer@gmail.com" target="_blank"><Icon color="black" link name="mail" size='huge'/></a>
    </div>
    <Header className="mainText" as="h1" style={{textAlign:"center"}}>Here are some of my projects</Header>
  
    <ProjectGroup/>

  </div>)
}

export default IndexPage
