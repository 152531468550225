import React, { Component } from 'react'
import { Button, Transition, Card, Image, Item, Reveal, Container, Responsive, Dropdown } from 'semantic-ui-react'
import Link from 'gatsby-link'
import "./main.css"

const projectImages = {
  "jump-into-stem": require('../images/jump-into-stem.png'),
  "refresh-so": require('../images/refresh-so.png'),
  "this-website": require('../images/this-website.png'),
  "amazon-mdsc": require('../images/amazon-logo.jpg'),
  "home-depot-rl": require('../images/rl-truck.png'),
  "rodney-smith": require('../images/rodney-smith.png'),
  "chaos-colleagues": require('../images/chaos-colleagues.jpg'),
  "lyft-data-challenge": require('../images/lyft.jpg'),
  "travel-berlin": require('../images/travel-berlin.jpg'),
  "project-shatter": require('../images/project-shatter/home-page.png'),
  "facial-recognition-game": require('../images/face-game.png'),
  "weather-algorithms": require('../images/weather.jpg'),
  "mtg-card-database": require('../images/magic-card.jpg'),
  "feetback": require('../images/feetback.png')
}
const filterOptions = [
  {
    key: 'All',
    text: 'All',
    value: 'all'
  },
  {
    key: 'Data Science/ML',
    text: 'Data Science/ML',
    value: 'ds'
  },
  {
    key: 'Web Development',
    text: 'Web Development',
    value: 'web'
  },
  {
    key: 'Other',
    text: 'Other',
    value: 'ds'
  },
]

const projectDict = {
  "jump-into-stem":
  {
    name: "JUMP into STEM Winner",
    description: "Won the JUMP into STEM data science competition",
    year: "2020",
    ds: true,
    web: false,
    other: false
  },
  "refresh-so":
  {
    name: "Refresh.so",
    description: "Building the easiest way to keep track of your meetings and contacts",
    year: "2020",
    ds: false,
    web: false,
    other: true
  },
  "this-website":
  {
    name: "This Website",
    description: "A portfolio website built using React and Gatsby",
    year: "2020",
    ds: false,
    web: true,
    other: false
  },

  "amazon-mdsc": {
    name: "Amazon Master Data Science Competition",
    description: "Finalist in a Data Science competition for Masters students",
    year: "2020",
    ds: true,
    web: false,
    other: false,
  },
  "home-depot-rl": {
    name: "Reinforcement Learning for deliveries",
    description: "Won prize in a Hackathon dedicated to using reinforcement learning to optimize delivery paths",
    year: "2020",
    ds: true,
    web: false,
    other: false,
  },
  "rodney-smith": {
    name: "Website for Rodney Smith",
    description: "Worked with a team of freelance developers to create a website for the late photographer Rodney Smith",
    year: "2019",
    ds: false,
    web: true,
    other: false,
  },
  "chaos-colleagues": {
    name: "Chaos Collegues",
    description: "Won best AR/VR hack at HackGT for our multiplatform VR/PC game",
    year: "2019",
    ds: false,
    web: false,
    other: true,
  },
  "lyft-data-challenge": {
    name: "Lyft Data Challenge",
    description: "Participated in a data science competition sponsored by Lyft, analyzing driver and trip data to evaluate drivers",
    year: "2019",
    ds: true,
    web: false,
    other: false,
  },
  "travel-berlin": {
    name: "Travel Berlin",
    year: "2019",
    description: "Led front end development to create web app to help tourists find locations of interest",
    ds: false,
    web: true,
    other: false,
  },
  "feetback": {
    name: "FeetBack",
    image: "@images/avatars/matthew.png",
    description: "Built a force sensitive insole to help lessen fall risk for the elderly by monitoring their gait using machine learning",
    year: "2018-2019",
    ds: true,
    web: false,
    other: true,
  },
  "project-shatter": {
    name: "Project Shatter",
    description: "Won People’s Choice award of statewide Colleigate Cup hackathon by developing a a website that teaches computer science to high school students in rural Georgia",
    year: "2019",
    ds: false,
    web: true,
    other: false,
  },
  "facial-recognition-game": {
    name: "Facial Recognition Game",
    description: "Developed a game that uses a machine learning model to detect facial expressions for players to score points",
    year: "2018",
    ds: true,
    web: true,
    other: false,
  },
  "weather-algorithms": {
    name: "Searching and sorting analysis on weather data",
    description: "Wrote paper in high school about which searching and sorting algorithms would be fastest for temperature data",
    year: "2018",
    ds: false,
    web: false,
    other: true,
  },
  "mtg-card-database": {
    name: "MTG Card Database and Inventory Manager",
    description: "Developed a database and GUI to help my business keep track of Magic: The Gathering cards, with searching and the ability to upload decks to check what was needed to complete them",
    year: "2017",
    ds: false,
    web: false,
    other: true,
  }
}

const allProjects = ["jump-into-stem","refresh-so", "this-website", "amazon-mdsc", "home-depot-rl", "rodney-smith", "chaos-colleagues", "lyft-data-challenge", "travel-berlin", "feetback", "project-shatter", "facial-recognition-game", "weather-algorithms", "mtg-card-database"]

export default class ProjectGroup extends Component {
  state = { hide: 0, show: 100, button: "all", projects: allProjects }

  checkDS(project) {
    return projectDict[project].ds;
  }
  checkWeb(project) {
    return projectDict[project].web;
  }
  checkOther(project) {
    return projectDict[project].other;
  }


  handleChange = (e, { value }) => {
    if (value === "all") this.handleAll()
    if (value === "ds") this.handleDS()
    if (value === "web") this.handleWeb()
    if (value === "other") this.handleOther()
  }
  handleAll = () => this.setState(({ button: "all", projects: allProjects }))
  handleDS = () => this.setState(({ button: "ds", projects: allProjects.filter(this.checkDS) }))
  handleWeb = () => this.setState(({ button: "web", projects: allProjects.filter(this.checkWeb) }))
  handleOther = () => this.setState(({ button: "other", projects: allProjects.filter(this.checkOther) }))

  render() {
    const { hide, show, projects } = this.state

    return (
      <div style={{ minHeight: "1025px", overflow: "hidden" }}>
        <Responsive minWidth={475} style={{ textAlign: "center", marginBottom: "20px" }}>
          <Button basic toggle active={this.state.button === "all"} onClick={this.handleAll} >All</Button>
          <Button basic toggle active={this.state.button === "ds"} onClick={this.handleDS} >Data Science/ML/AI</Button>
          <Button basic toggle active={this.state.button === "web"} onClick={this.handleWeb} >Web Development</Button>
          <Button basic toggle active={this.state.button === "other"} onClick={this.handleOther} >Other</Button>
        </Responsive>
        <Responsive maxWidth={475} style={{ textAlign: "center", marginBottom: "20px" }}>
          <Dropdown selection button options={filterOptions} defaultValue="all" onChange={this.handleChange}>
          </Dropdown>
        </Responsive>
        <Container>
          <div style={{ textAlign: "center" }}>
            <Transition.Group
              as={Card.Group}
              duration={{ hide, show }}
              textAlign='right'
              animation="bounce"
              centered
            >
              {projects.map((item) => (
                <Link to={item}>
                  <Reveal className="project" instant animated='fade' style={{ margin: "10px" }}>
                    <Reveal.Content visible>
                      <Image src={projectImages[item]} rounded style={{ backgroundColor: "white", height: "200px", width: "300px", objectFit: "cover" }} />

                    </Reveal.Content>
                    <Reveal.Content hidden>
                      <div style={{ height: "200px", width: "300px" }}>
                        <Image src={projectImages[item]} rounded style={{ backgroundColor: "white", height: "200px", width: "300px", objectFit: "cover", filter: "brightness(25%)" }}></Image>
                        <Item style={{ position: "absolute", top: "10px", marginLeft: "5px", marginRight: "5px" }}>
                          <Item.Content>
                            <Item.Header style={{ color: "white" }} as="h3">{projectDict[item].name}</Item.Header>
                            <Item.Meta style={{ color: "white" }}>{projectDict[item].year}</Item.Meta>
                            <Item.Description style={{ color: "white" }}>{projectDict[item].description}</Item.Description>
                          </Item.Content>
                        </Item>
                      </div>
                    </Reveal.Content>
                  </Reveal>
                </Link>
              ))}
            </Transition.Group>
          </div>
        </Container>
      </div>
    )
  }
}