import "./main.css"
import React, { Component } from 'react'
import { Image } from "semantic-ui-react"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ContraryLogo from "@images/icons/contrary-logo.png"
import FacebookLogo from "@images/icons/facebook-logo.png"
import RecurrencyLogo from "@images/icons/recurrency.jpeg"
import GPLogo from "@images/icons/gp-logo.png"
import GTLogo from "@images/icons/gt-logo.jpg"


export default class WorkTimeline extends Component {
    render() {
        return (<VerticalTimeline>
            {/* <VerticalTimelineElement
            className="vertical-timeline-element--work main-text"
            contentStyle={{ background: '#B3A369', color: 'white' }}
            contentArrowStyle={{ borderRight: '7px solid  #B3A369' }}
            date="2021 - 2022"
            iconStyle={{ background: '#B3A369', color: '#fff' }}
            dateClassName="mainText"
            icon={<Image src={GTLogo} circular></Image>}
        >
            <h3 className="vertical-timeline-element-title">Graduate Student at Georgia Tech</h3>
            <h4 className="vertical-timeline-element-subtitle placeText">Atlanta, GA</h4>
            <p>
            Getting a Master's degree in Machine Learning doing the BS/MS program
            </p>
        </VerticalTimelineElement> */}
            <VerticalTimelineElement
                className="vertical-timeline-element--work main-text"
                contentStyle={{ background: '#0f2f47', color: 'white' }}
                contentArrowStyle={{ borderRight: '7px solid  #0f2f47' }}
                date="May 2021-Present"
                dateClassName={"dateText"}
                iconStyle={{ background: '#fff', color: '#fff', padding: "5px" }}
                icon={<Image src={RecurrencyLogo} circular />}
            >
                <h3 className="vertical-timeline-element-title">Software Engineer at Recurrency</h3>
                <h4 className="vertical-timeline-element-subtitle placeText">Remote</h4>
                <p>
                    Recurrency is building the automated enterprise. Our product is the world's first enterprise optimization system (EOS), a machine learning application that integrates with legacy enterprise resource planners (ERP) like SAP, Oracle, Microsoft Dynamics, or Epicor, and provides the user with the flows and recommendations on how to better buy, manage, price, and sell their inventory. If you're interested in joining the team get in touch!
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work main-text"
                contentStyle={{ background: '#3b5998', color: 'white' }}
                contentArrowStyle={{ borderRight: '7px solid  #3b5998' }}
                date="May-August 2020"
                dateClassName={"dateText"}
                iconStyle={{ background: '#3b5998', color: '#fff', padding: "5px" }}
                icon={<Image src={FacebookLogo} circular />}
            >
                <h3 className="vertical-timeline-element-title">Enterprise Engineering Intern at Facebook</h3>
                <h4 className="vertical-timeline-element-subtitle placeText">Remote</h4>
                <p>
                    While working at Facebook, I built a generic framework for attaching objects using Hack, as well as the UI to display them using React, Relay, and GraphQL. I brought it into production with thousands of attachments being created every day by hundreds of data center employees around the world.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work main-text"
                contentStyle={{ background: 'rgb(67,92,254)', color: 'white' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(67,92,254)' }}
                date="October 2019-May 2021"
                iconStyle={{ background: 'rgb(67,92,254)', color: '#fff', padding: "2px", paddingLeft: "3px", paddingTop: "7px" }}
                dateClassName={"dateText"}
                icon={<Image src={ContraryLogo} circular />}
            >
                <h3 className="vertical-timeline-element-title">Venture Partner at Contrary Capital</h3>
                <h4 className="vertical-timeline-element-subtitle placeText">Atlanta, GA</h4>
                <p>
                    Contrary is a venture fund that identifies the world’s top early-career engineers, designers, and product minds, connects them with a close-knit, career-long support community, and invests in companies they start or join.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work main-text"
                contentStyle={{ background: '#B3A369', color: 'white' }}
                contentArrowStyle={{ borderRight: '7px solid  #B3A369' }}
                date="Summer 2019"
                iconStyle={{ background: '#B3A369', color: '#fff' }}
                dateClassName="dateText"
                icon={<Image src={GTLogo} circular></Image>}
            >
                <h3 className="vertical-timeline-element-title">Study Abroad</h3>
                <h4 className="vertical-timeline-element-subtitle placeText">Berlin, Germany</h4>
                <p>
                    Delved deeper into my education with Georgia Tech professors in an exclusive 30-student program for computer science majors.
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work main-text"
                contentStyle={{ background: '#B3A369', color: 'white' }}
                contentArrowStyle={{ borderRight: '7px solid  #B3A369' }}
                date="2018 - 2021"
                iconStyle={{ background: '#B3A369', color: '#fff' }}
                dateClassName="dateText"
                icon={<Image src={GTLogo} circular></Image>}
            >
                <h3 className="vertical-timeline-element-title">Bachelor's Degree at Georgia Tech</h3>
                <h4 className="vertical-timeline-element-subtitle placeText">Atlanta, GA</h4>
                <p>
                    Major in Computer Science<br />GPA: 3.97
                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work main-text"
                contentStyle={{ background: 'rgb(80,166,237)', color: 'white' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(80,166,237)' }}
                date="June-August 2018"
                iconStyle={{ background: 'rgb(80,166,237)', color: '#fff', padding: "5px" }}
                icon={<Image src={GPLogo}></Image>}
                dateClassName="dateText"
            >
                <h3 className="vertical-timeline-element-title">Intern at Growth Prospects</h3>
                <h4 className="vertical-timeline-element-subtitle placeText">Boulder, CO</h4>
                <p>
                    For this startup, I implemented an A/B test of a direct mail marketing campaign that increased response rates by 200% over email alone. Using Google Sheets database functions, I consolidated metrics from multiple clients and researchers, saving management significant time.
                </p>
            </VerticalTimelineElement>

        </VerticalTimeline>
        )
    }
}
