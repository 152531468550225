import "./main.css"
import React, { Component, createRef } from 'react'
import {
  Grid,
  Header,
  Rail,
  Ref,
  Segment,
  Icon,
  Sticky, Transition, Visibility
} from "semantic-ui-react"

export default class IntroSticky extends Component {
  state = {
    calculations: {
      direction: 'none',
      height: 0,
      width: 0,
      topPassed: false,
      bottomPassed: false,
      pixelsPassed: 0,
      percentagePassed: 0,
      topVisible: false,
      bottomVisible: false,
      fits: false,
      passing: false,
      onScreen: false,
      offScreen: false,
    },
  }
  contextRef = createRef()
  handleUpdate = (e, {calculations}) => this.setState({ calculations })

  render() {
    if (typeof window !== 'undefined') {
      // Make scroll behavior of internal links smooth
      require('smooth-scroll')('a[href*="#"]');
    }
    const { calculations } = this.state
    return (<div>
      <Transition visible={(!calculations.onScreen && !calculations.bottomPassed)} animation='fade' duration={100}>
          <a href="/#me" style={{position:"fixed", zIndex: "1",top:"85vh", left: "50%",transform: "translate(-50%, -50%)"}}><Icon size="huge" style={{}} color="black" name="angle down"></Icon></a>
        </Transition>
      <Grid centered columns={1}>
        <Grid.Column>
        
          <Ref innerRef={this.contextRef}>
            <Segment basic centered textAlign="left">
                <Rail textAlign="left">
                    <Sticky context={this.contextRef}>
                        <Header className="mainText" as='h1' style={{paddingTop:"45vh",paddingLeft:"15vw", fontFamily:"Helvetica Neue, Lato", fontSize:"5vw"}}>Hi,&nbsp;I'm</Header>
                    </Sticky>
                </Rail>
                    <Header className="introText" as='h1' style={{paddingTop:"55vh",paddingBottom:"60vh", paddingLeft:"31.75vw", fontFamily:"Helvetica Neue", fontSize:"5vw"}}>a Web Developer</Header>
                    
                    
                    <Visibility onUpdate={this.handleUpdate}>
                      <Header className="introText" as='h1' style={{paddingBottom:"60vh", paddingLeft:"31.75vw", fontFamily:"Helvetica Neue", fontSize:"5vw"}}>a Data Scientist</Header>
                    </Visibility>
                    <Header className="introText" as='h1' style={{paddingLeft:"31.75vw", fontFamily:"Helvetica Neue", fontSize:"5vw"}}>a Software Engineer</Header>
                    <Sticky>
                    <Header className="introText" as='h1' style={{paddingTop:"60vh",paddingLeft:"31.75vw", fontFamily:"Helvetica Neue", fontSize:"5vw"}}><span id="me">Lucas Kiefer.</span></Header>
                    </Sticky>
            </Segment>
          </Ref>
        </Grid.Column>
      </Grid>
      </div>
    )
  }
}